<template>
    <div class="eui-header">
      <div class="web-view-box">
        <div class="index-logo"
          >
          <img src="~@/images/logo.png"
        />
        </div>
        <div class="index-nav">
          <ul id="nav" class="clearfix">
            <li class="nLi" v-for="(item,index) in navList" :key="index" :class="$route.path == item.url?'on':''">
              <h3><a @click="goto(item.url,item.id)">{{item.resName}}</a></h3>
            </li>
          </ul>
        </div>
      </div>
      <div class="h5-view-box" style="z-index: 1000">
        <div class="index-logo"
          >
          <img src="~@/images/logo.png"
        />
        </div>
        <el-button icon="el-icon-s-fold" @click="drawer = true" circle></el-button>
        <el-drawer :visible.sync="drawer" :append-to-body="true" size="80%">
          <div class="h5-index-nav">
            <ul id="nav" class="clearfix">
              <li class="nLi" v-for="(item,index) in navList" :key="index" :class="$route.path == item.url?'on':''" 
                data-aos="fade-right"
                :data-aos-delay="100*(index + 1)">
                <h3><a @click="goto(item.url,item.id)">{{item.resName}}</a></h3>
              </li>
            </ul>
          </div>
        </el-drawer>
      </div>
    </div>
</template>


<script>
export default {
  name: "IndexNav",
  data() {
    return {
      navList: [
        // { a: "/", navItem: "首页" },
        // { a: "/companyIntro", navItem: "关于泰昌" },
        // { a: "/productService", navItem: "产品与服务" },
        // { a: "/companyCulture", navItem: "公司文化" },
        // { a: "/center", navItem: "新闻资讯" },
        // { a: "/talentRecruit", navItem: "人才招聘" },
        // { a: "/businessCoop", navItem: "商务合作" },
      ],
      drawer: false
    };
  },
  methods: {
    goto(path, id) {
      this.drawer = false
      this.$router.push({path: path, query: {navId: id}});
    },
  },
  mounted () {
    let that = this
    that.$api.ajaxPost('vueOfficialWebsite/getResourceList').then(res => {
      if(res.success){
        that.navList = res.data
        that.$store.state.resourceData = res.data
      }
    });
  },
};
</script>

<style lang="scss" scoped>

.eui-header .index-nav{display: table-cell;}
.eui-header .index-nav #nav{padding-left: 20px;}
.eui-header .index-nav .nLi{float: left;position:relative;margin-left: -20px;}
.eui-header .index-nav .nLi h3{position: relative; z-index: 2; margin: 0; font-weight: normal;}
.eui-header .index-nav .nLi h3 a{display: block;color: #fff;text-shadow: 1px 1px 5px rgba(0,0,0,.5);display: block;line-height: 30px;padding: 25px 35px;font-size: 18px;transition: all .9s;}
.eui-header .index-nav li a:hover,.eui-header .index-nav li.on h3 a{color: #eb6100;cursor: pointer;}
.eui-header {transition: all .6s;background-color: rgba(0, 0, 0, 0.747);height: 65px;}
.h5-view-box {display: none;}
@media screen and (max-width: 750px){
	.web-view-box {
    display: none;
  }
  .h5-view-box {
    display: block;
    width: 100%;
    height: 50px;
    position: relative;
  }
  .eui-header {
    width: 100%;
    height: 50px;
    display: block;
    padding: 0;
    .index-logo {
      float: left;
      height: 50px;
      width: auto;
      img {height: 100%}
    }
    .el-button {
      float: left;
      width: 40px;
      height: 40px;
      font-size: 24px;
      position: absolute;
      right: 10px;
      top: 5px;
      padding: 5px;
    }
    
  }
}
</style>
<style>
@media screen and (max-width: 750px){
  .el-drawer__close-btn {
    font-size: 40px;
  }
  .h5-index-nav {
      width: 100%;
  }
  .h5-index-nav li.on h3 a{color: #eb6100;cursor: pointer;}
  .h5-index-nav .nLi{display: block;position:relative;padding: 0 10px;}
  .h5-index-nav .nLi a{color: #333;display: block;line-height: 34px;padding: 5px 24px;font-size: 18px;transition: all .9s;font-weight: normal;text-align: right;}
}
</style>
